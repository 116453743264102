import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import RequireAuth from './services/RequireAuth';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={
          <RequireAuth>
            <Navigate to="/" replace />
          </RequireAuth>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
