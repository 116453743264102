import './alerts.scss';

const Alert = (type='danger', message='An unknown error occured', extra=null, index) => (
  <div className={"alert fade alert-" + type} role="alert" key={index}>
    {message}
    {
      extra ?
      <>
        <br/>
        {extra}
      </>
      :
      null
    }
  </div>
)

const Alerts = ({ alerts }) => (
  <section id="alert-section">
    {
      alerts ?
        alerts.map((alert, index) => Alert(alert.status, alert.message, alert.extra, index))
      :
        null
    }
  </section>
)

export default Alerts;
