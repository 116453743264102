import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import "./login.scss";
import AuthService from "../../services/Authentication";

const log = (navigate, username, password, newPage) => {
  if (!username) {
    alert("Username field not filled");
  }
  else if (!password) {
    alert("Password field not filled");
  }
  else {
    AuthService.login(username, password)
      .then(_resp => {
        navigate(`${newPage || "/"}`);
      })
      .catch(err => {
        if (process.env.REACT_APP_ENV !== 'production') console.error(err);
        if (err?.response?.data?.error?.message) alert(err.response.data.error.message);
      })
  }
}

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <React.Fragment>
      <div className="background-login"></div>
      <div id="patern-background"></div>
      {/* <div id="particles-js"></div> */}
      <div className="login-page-container">
        <div className="login-container">
          <a className="title-box-login" href="https://datascientest.com">
            <img src="/media/logo.svg" alt="logo_datascientest" />
            <h3>DataScientest.com</h3>
          </a>
          <form className="text-center" onSubmit={(event) => {
            log(navigate, username, password, searchParams.get('next'));
            event.preventDefault();
          }}>
            <div className="block-input">
              <label htmlFor="username_input">Username</label>
              <input
                id="username_input"
                type="username"
                autoCapitalize="off"
                autoCorrect="off"
                className="form-control"
                name="username"
                value={username}
                tabIndex="1"
                autoFocus="autofocus"
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="block-input">
              <label htmlFor="password_input">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                id="password_input"
                tabIndex="2"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="block-input button-content text-center">
              <a href="/" className="mb-3">J'ai oublié mon mot de passe</a>
              <button type="submit" id="login_submit" tabIndex="3">Se connecter</button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
