import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ children }) {
    let location = useLocation();

    if (!localStorage.getItem('access_token')) {
      return <Navigate to={`/login?next=${location?.pathname}`} replace />;
    }

    return children;
}
