import './opinion.scss';

const Opinion = () => (
  <section className='bg-white p-3 px-md-5'>
    <h2 className='my-3'>Your opinion matters!</h2>
    <p>We're glad you participated to challenge with us, we would appreciate to receive your opinion by this Google form.</p>
    <a href={process.env.REACT_APP_OPINION_LINK} target="_blank" rel='noreferrer'>
      <button type="button" className='btn my-2 mr-2 purple-btn'>
        <i className="fab fa-wpforms"></i> Give your feedback
      </button>
    </a>
  </section>
)

export default Opinion;
