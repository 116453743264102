import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import "./uploader.scss";

const _uploadFile = (challengeId, fileToUpload, addToAlerts, setUploading) => {
  const data = new FormData();
  data.append('file', fileToUpload);
  setUploading(true);

  UserService.uploadFile(challengeId, data)
    .then(resp => {
      console.log(resp);
      addToAlerts({status: 'info', message: `${resp.message} Your work got marked ${resp.score}`, extra: 'Please refresh for seeing your new place in leaderboard'});
    })
    .catch(err => {
      console.error("err", err);
      addToAlerts({status: 'danger', message: err?.data?.error?.message || err?.message});
    })
    .finally(_ => setUploading(false));
}

const Uploader = ({ challengeId, addToAlerts, started, ended }) => {
  const [inputFileValue, setInputFileValue] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
  }, [inputFileValue]);

  return (
    <section className='p-3 px-md-5 bg-white' id="upload-section">
      <h2 className='my-3'>Upload your work</h2>
      {
        !ended && started ?
        <div>
          <label className='my-3 text-center p-5 w-100' htmlFor="input-file">
            <input type="file"
              id="input-file"
              className="d-none"
              onChange={(event) => setInputFileValue(event.target.files[0])}
            />
            {
              !inputFileValue ?
              (<span>
                <i className="fas fa-search fa-2x mb-3"></i>
                <br/>
                Choose a file
              </span>)
              :
              (<span>
                <i className="fa fa-file fa-2x mb-3"></i>
                <br/>
                {inputFileValue.name}
              </span>)
            }
          </label>
          {
            inputFileValue &&
              (
                <button
                  onClick={() => !uploading ? _uploadFile(challengeId, inputFileValue, addToAlerts, setUploading) : null}
                  type="button"
                  className={`btn btn-primary my-3 mx-auto d-block ${uploading ? 'disabled' : ''}`}>
                    {
                    uploading ?
                    <>Uploading ...</>
                    :
                    (
                      <>
                      <i className="fa fa-upload"></i> Upload file
                      </>
                    )
                  }
                </button>
              )
          }
        </div>
        :
        (
          started ?
          <div>
            <p>Challenge has ended <i className="fas fa-hourglass-end"></i></p>
          </div>
          :
          <div>
            <p>Challenge has not started yet <i className="fas fa-calendar-times"></i></p>
          </div>
        )
      }
    </section>
  );
}

export default Uploader;
