import React from 'react';
import "./subject.scss";

const Subject = ({ title, description, attachments }) => (
  <section className='p-3 px-md-5 bg-white'>
    <h2 className='my-3'>{title}</h2>
    <p>{description}</p>
    {
      attachments?.map((attachment, attachmentIndex) =>
        attachment?.is_available === undefined || attachment.is_available === true ?
        <a href={attachment.link} key={attachmentIndex} target="_blank" rel="noreferrer">
          <button type="button" className="btn btn-info mr-2 mb-2">
            <i className={`fa fa-${attachmentIndex ? 'database' : 'file'}`}></i> {attachment.name}
          </button>
        </a>
        :
        null
      )
    }
  </section>
);

export default Subject;
