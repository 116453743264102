import axios from 'axios';
import Authentication from './Authentication';

class AdminService {

  freezeScoreboard(challengeId) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if (challengeId === undefined) {
        return reject('Need challengeId for freezing');
      }
      axios.post(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/scoreboard/freeze`, {}, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(({ data }) => resolve(data))
        .catch(async err => {
          console.error("Error in freezeScoreboard", err);
          if ([401, 422].includes(err?.response?.status)) {
            await Authentication.refresh();
            resolve(this.freezeScoreboard(challengeId));
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err);
          }
        });
    });
  }

  getMachineList() {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}machines/admin/status`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(({ data }) => resolve(data.instances))
        .catch(async err => {
          console.error("Error in getMachineList", err);
          if ([401, 422].includes(err?.response?.status)) {
            await Authentication.refresh();
            resolve(this.getMachineList());
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err);
          }
        });
    });
  }

  handleMachine(action, machineId) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_API_URL}machines/admin/${action}${machineId ? `/${machineId}` : ''}`, {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(() => resolve())
        .catch(async err => {
          console.error(`Error in ${action} Machine`, err);
          if ([401, 422].includes(err?.response?.status)) {
            await Authentication.refresh();
            resolve(this.handleMachine(action, machineId));
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err);
          }
        });
    });
  }

  getScoreBoard(challengeId) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if (challengeId === undefined) {
        return reject('Challenge\'s id needs to be set');
      }
      axios.get(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/admin/scoreboard`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(({ data }) => resolve(data.result))
      .catch(async err => {
        console.error("Error in getScoreBoard", err);
        if ([401, 422].includes(err?.response?.status)) {
          try {
            await Authentication.refresh();
            resolve(this.getScoreBoard(challengeId));
          }
          catch(err) {
            reject(err);
          }
        } else if (err.response === undefined) {
          alert("Connection lost");
        } else {
          reject(err);
        }
      });
    })
  }

  inviteMember(challengeId, userPayload) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if ([challengeId, userPayload].includes(undefined)) {
        return reject('Challenge\'s id and user payload need to be set');
      }
      axios.post(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/admin/users`, userPayload, {
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      })
      .then(({ data }) => resolve(data.user))
      .catch(async err => {
        console.error("Error in inviteMember", err);
        if ([401, 422].includes(err?.response?.status)) {
          try {
            await Authentication.refresh();
            resolve(this.inviteMember(challengeId, userPayload));
          }
          catch(err) {
            reject(err);
          }
        } else if (err.response === undefined) {
          alert("Connection lost");
        } else {
          reject(err);
        }
      });
    })
  }

  postTeamScore(challengeId, scorePayload) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if ([challengeId, scorePayload].includes(undefined)) {
        return reject('Challenge\'s id and user payload need to be set');
      }
      axios.post(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/scoreboard/admin/score`, scorePayload, {
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      })
      .then(({ data }) => resolve(data))
      .catch(async err => {
        console.error("Error in postTeamScore", err);
        if ([401, 422].includes(err?.response?.status)) {
          try {
            await Authentication.refresh();
            resolve(this.postTeamScore(challengeId, scorePayload));
          }
          catch(err) {
            reject(err);
          }
        } else if (err.response === undefined) {
          alert("Connection lost");
        } else {
          reject(err);
        }
      });
    })
  }

}

export default new AdminService();
