import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import FormModale from "../form-modale/FormModale";
import "./leaderboard.scss";

const _member_add_inputs = [
  {
    type: "text",
    name: "username",
    placeholder: "User's name",
  },
  {
    type: "text",
    name: "email",
    placeholder: "User's email",
  },
  {
    type: "text",
    name: "password",
    placeholder: "User's password",
  },
];

const _score_add_inputs = [
  {
    type: "number",
    max: 1,
    min: 0,
    name: "public_score",
    placeholder: "Public score",
  },
  {
    type: "number",
    max: 1,
    min: 0,
    name: "private_score",
    placeholder: "Private score",
  },
  {
    type: "select",
    options: [],
    name: "user_id",
    placeholder: "Select user",
  },
];

/**
 * Converts datetime into readable string for leaderboard
 * @param {string} dateTime datetime to convert
 * @returns datetime in better format
 */
const _convertDateTimeToString = (dateTime) =>
  new Date(dateTime).toLocaleString();

/**
 * Generates table row depending on team infos + actual user infos
 * @param {number} index index in the table
 * @param {object} param1 group's infos
 * @param {object} param2 actual user's infos
 * @param {object} actions informations about actions buttons to be shown
 * @returns a \<td\> containing all needed infos
 */
const TeamRow = (
  index,
  {
    group_id,
    name,
    members,
    public_score,
    private_score,
    push_count,
    last_push_dt,
  },
  { groupId, userName },
  { displayActions, setShowAddMember, setShowAddScore, setSelectedGroup }
) => (
  <tr key={index} className={group_id === groupId ? "table-info" : null}>
    <td className="text-center">{index}</td>
    <td>{name || "N/A"}</td>
    <td>
      {members.map((member, memberIndex) => (
        <span
          className={
            "d-inline-flex align-items-center justify-content-center badge badge-pill mr-2 text-white align-text-bottom badge-" +
            (userName === member && members.indexOf(userName) === memberIndex
              ? "info"
              : "warning")
          }
          key={memberIndex}
          data-toggle="tooltip"
          data-placement="left"
          title={member}
        >
          {member.at(0).toUpperCase()}
        </span>
      ))}
    </td>
    <td>{public_score !== undefined ? public_score : "N/A"}</td>
    {private_score !== undefined ? (
      <td>{private_score !== undefined ? private_score : "N/A"}</td>
    ) : null}
    <td>{push_count || "N/A"}</td>
    <td>
      {last_push_dt && last_push_dt !== "None"
        ? _convertDateTimeToString(last_push_dt)
        : "N/A"}
    </td>
    {displayActions && (
      <td>
        <button
          type="button"
          onClick={() => {
            setSelectedGroup(group_id);
            setShowAddMember(true);
          }}
          className="btn btn-info btn-sm my-2 mr-2"
        >
          <i className="fas fa-plus"></i> Add member
        </button>
        <button
          type="button"
          onClick={() => {
            setSelectedGroup(group_id);
            setShowAddScore(true);
          }}
          className="btn btn-info btn-sm my-2"
        >
          <i className="fas fa-plus"></i> Add score
        </button>
      </td>
    )}
  </tr>
);

const Leaderboard = ({
  data,
  groupId,
  isAdmin,
  challengeId,
  userName,
  frozen,
  actions = false,
}) => {
  const [showAddMember, setShowAddMember] = useState(false);
  const [showAddScore, setShowAddScore] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (window?.["$"]) {
      window["$"](() => {
        window["$"]('[data-toggle="tooltip"]').tooltip();
      });
    }
  }, []);

  return (
    <section className="bg-white p-3 px-md-5">
      <h2 className="my-3">
        Leaderboard{" "}
        {frozen ? (
          <span className="badge badge-info align-bottom">
            Frozen since {new Date(frozen).toLocaleString()}
          </span>
        ) : null}
      </h2>
      <table className="py-3 table table-bordered table-sm">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Team name</th>
            <th>Members</th>
            <th>{`${
              data?.[0]?.private_score !== undefined
                ? "Public Score"
                : "Score ↓"
            }`}</th>
            {data?.[0]?.private_score !== undefined ? (
              <th>Private Score ↓</th>
            ) : null}
            <th>Entries</th>
            <th>Last render</th>
            {actions && isAdmin && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data
            ? data.map((team, index) =>
                TeamRow(
                  index + 1,
                  team,
                  { groupId, userName },
                  {
                    displayActions: actions && isAdmin,
                    setShowAddMember,
                    setShowAddScore,
                    setSelectedGroup,
                  }
                )
              )
            : null}
        </tbody>
      </table>
      {showAddMember && (
        <FormModale
          title={"Add Member"}
          submitFunction={(inputs) => {
            const [username, email, password] = inputs;

            AdminService.inviteMember(challengeId, {
              username,
              email,
              password,
              group_id: selectedGroup,
            })
              .then((_) => {
                alert("User added successfully");
                window.location.reload();
              })
              .catch((_) => {
                alert("User has not been added. An error occurred");
              });
          }}
          inputs={_member_add_inputs}
          hideFunction={() => setShowAddMember(false)}
        />
      )}
      {showAddScore && (
        <FormModale
          title={"Add Score"}
          submitFunction={(inputs) => {
            const [public_score, private_score, username] = inputs;

            AdminService.postTeamScore(challengeId, {
              public_score: +(public_score.replace(',', '.')),
              private_score: +(private_score.replace(',', '.')),
              username,
            })
              .then((_) => {
                alert("Score added successfully");
                window.location.reload();
              })
              .catch((_) => {
                alert("Score has not been added. An error occurred");
              });
          }}
          inputs={(() => {
            let tmp = [..._score_add_inputs];

            tmp.find((elem) => elem.name === "user_id").options = [
              ...data.find((group) => group.group_id === selectedGroup).members,
            ];
            return tmp;
          })()}
          hideFunction={() => setShowAddScore(false)}
        />
      )}
    </section>
  );
};

export default Leaderboard;
