import axios from "axios";

// JWT Parser
const _parseJwt = token => {
  let base64Url = token.split('.')[1],
      base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
      jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c =>
        `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`
      ).join(''));

  return JSON.parse(jsonPayload);
};

class AuthService {

  login(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/login`, {
          username,
          password
        })
        .then(({ data }) => {
          const { access_token, refresh_token, ...rest } = data.return;

          if (access_token) {
            localStorage.setItem('access_token', access_token);
            let token = _parseJwt(access_token);
            // Set cookie for heavy machines
            document.cookie = `user=${token.jti}; Domain=${process.env.REACT_APP_DOMAIN}; Secure; Path=/`;
          }
          if (refresh_token) {
            localStorage.setItem('refresh_token', refresh_token);
          }
          resolve(rest);
        })
        .catch(err => {
          console.error("Error in login", err);
          reject(err);
        })
    });
  }

  logout() {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/logout`, {
          access_token,
          refresh_token
        })
        .then(() => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          let token = _parseJwt(access_token);
          // Set expired cookie for heavy machines
          document.cookie = `user=${token.jti}; Domain=${process.env.REACT_APP_DOMAIN}; Secure; Path=/; Max-Age=0`;
          resolve();
        })
        .catch(err => {
          console.error("Error in logout", err);
          if (err.response.status === 401) {
            console.warn("Removing auth…");
            // If token just expired, to enable to log out
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            let token = _parseJwt(access_token);
            // Set expired cookie for heavy machines
            document.cookie = `user=${token.jti}; Domain=${process.env.REACT_APP_DOMAIN}; Secure; Path=/; Max-Age=0`;
            resolve();
          } else {
            reject(err);
          }
        })
    });
  }

  refresh() {
    const refresh_token = localStorage.getItem('refresh_token');

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/refresh`, {
        }, {
          headers: {
            Authorization: `Bearer ${refresh_token}`
          }
        })
        .then(({ data }) => {
          localStorage.setItem('access_token', data.access_token);
          resolve(data.access_token);
        })
        .catch(err => {
          console.error("Error in refresh", err);
          if (err.response.status === 401) {
            this.logout()
              .then(() => window.location.reload());
          } else {
            reject(err);
          }
        })
    });
  }

  getCurrentUser() {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    return {
      access_token,
      refresh_token
    };
  }

}

export default new AuthService();
