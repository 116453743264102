import { useEffect } from "react";

const _capitalizeSentence = (text) =>
  `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;

const FormModale = ({
  id = `modale-${Date.now()}`,
  title,
  inputs,
  submitFunction,
  hideFunction,
}) => {
  useEffect(() => {
    window?.["$"](`#${id}`).modal("show");
    window?.["$"](`#${id}`).on("hidden.bs.modal", () => {
      hideFunction();
    });

    return () => {
      window?.["$"](`#${id}`).modal("hide");
    };
  }, [id, hideFunction]);

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`${id}CenterTitle`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}LongTitle`}>
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {inputs?.map((elem, index) =>
              elem.type === "select" ? (
                <select
                  className="custom-select mb-2"
                  key={`modale-${id}-select-${index}`}
                >
                  {elem.options.map((option, option_index) => (
                    <option
                      value={option}
                      key={`modale-${id}-select-${index}-option-${option_index}`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="form-control mb-2"
                  key={`modale-${id}-input-${index}`}
                  {...elem}
                  placeholder={_capitalizeSentence(
                    elem.placeholder || elem.name
                  )}
                />
              )
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                submitFunction(window?.["$"]("input, select").map((_index, elem) => window?.["$"](elem).val()))
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModale;
