import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import "./machine-status.scss";

const _fetchMachineStatus = (setMachineStatus) => {
  UserService.getMachineStatus()
    .then(resp => setMachineStatus({
      message: resp.message,
      machineCode: resp.machine_status
    }))
    .catch(err => console.error(err))
}

const MachineStatus = ({ usermail, challengeId }) => {
  const [machineStatus, setMachineStatus] = useState({
    message: "Machine is being created",
    machineCode: 201
  });

  useEffect(() => {
    _fetchMachineStatus(setMachineStatus)
    const interval = setInterval(() => _fetchMachineStatus(setMachineStatus), 30000);
    return () => clearInterval(interval)
  }, []);

  return (
    <section className='p-3 px-md-5 bg-white'>
      <p id="machine-text" className={`my-3 color-${machineStatus.machineCode}`}>
        <span><i className="fas fa-server fa-xl"></i> {machineStatus.message}</span>
        {
          machineStatus.machineCode === 200 &&
          <a id="machine-button" href={`/events/${usermail}/challenge/${challengeId}/0`} target="_blank" rel="noreferrer">
            <button type="button" className="btn btn-dark">
              Go 
              <i className="fas fa-rocket"></i>
            </button>
          </a>
        }
      </p>
    </section>
  );
}

export default MachineStatus;
