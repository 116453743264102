import axios from 'axios';
import Authentication from './Authentication';

class UserService {

  getUserInfo() {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}users/me`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(({ data }) => resolve(data.self))
        .catch(async err => {
          console.error("Error in getUserInfo", err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              await Authentication.refresh();
              resolve(this.getUserInfo());
            }
            catch(err) {
              reject(err);
            }
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err);
          }
        });
    });
  }

  getChallengeInfo(challengeId) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}challenges/${challengeId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
        .then(({ data }) => resolve(data.challenge))
        .catch(async err => {
          console.error("Error in getChallengeInfo", err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              await Authentication.refresh();
              resolve(this.getChallengeInfo(challengeId));
            }
            catch(err) {
              reject(err);
            }
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err);
          }
        });
    });
  }

  getScoreBoard(challengeId) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if (challengeId === undefined) {
        return reject('Challenge\'s id needs to be set');
      }
      axios.get(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/scoreboard`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(({ data }) => resolve(data.result))
      .catch(async err => {
        console.error("Error in getScoreBoard", err);
        if ([401, 422].includes(err?.response?.status)) {
          try {
            await Authentication.refresh();
            resolve(this.getScoreBoard(challengeId));
          }
          catch(err) {
            reject(err);
          }
        } else if (err.response === undefined) {
          alert("Connection lost");
        } else {
          reject(err);
        }
      });
    })
  }

  getMachineStatus() {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_API_URL}machine/status`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(({ data }) => resolve(data))
      .catch(async err => {
        console.error("Error in getMachineStatus", err);
        if ([401, 422].includes(err?.response?.status)) {
          try {
            await Authentication.refresh();
            resolve(this.getMachineStatus());
          }
          catch(err) {
            reject(err);
          }
        } else if (err.response === undefined) {
          alert("Connection lost");
        } else {
          reject(err);
        }
      });
    })
  }

  uploadFile(challengeId, file) {
    const access_token = localStorage.getItem('access_token');

    return new Promise((resolve, reject) => {
      if (!file) return reject('Need file for uploading');
      axios.post(`${process.env.REACT_APP_API_URL}challenges/${challengeId}/scoreboard`, file, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data"
        }
      })
        .then(({ data }) => resolve(data))
        .catch(async err => {
          console.error("Error in uploadFile", err);
          if ([401, 422].includes(err?.response?.status)) {
            try {
              await Authentication.refresh();
              resolve(this.uploadFile(challengeId, file));
            }
            catch(err) {
              reject(err);
            }
          } else if (err.response === undefined) {
            alert("Connection lost");
          } else {
            reject(err.response);
          }
        });
    });
  }

}

export default new UserService();
