import AdminService from "../../services/AdminService";
import { CSVLink } from "react-csv";

const _freezeScoreboard = (challengeId, addToAlerts) => {
  AdminService.freezeScoreboard(challengeId)
    .then((_) => {
      addToAlerts({
        status: "info",
        message: "Scoreboard frozen",
      });
    })
    .catch((err) => {
      console.error(err);
      addToAlerts({
        status: "danger",
        message: err,
      });
    });
};

const Commander = ({
  challengeId,
  addToAlerts,
  isFrozen,
  leaderboard,
  showTitle = true,
}) => {
  var csvReport;
  if (leaderboard.length) {
    const headers = Object.keys(leaderboard[0]).map((key) => ({
      label: `${key.at(0).toUpperCase()}${key.slice(1).toLowerCase()}`,
      key,
    }));
    const data = leaderboard;
    csvReport = {
      headers,
      data,
      filename: `${Date.now()}-leaderboard.csv`,
    };
  }

  return (
    <section className="bg-white p-3 px-md-5">
      {showTitle && <h2 className="my-3">Hello Admin!</h2>}
      <div className="my-3">
        <button
          onClick={() => _freezeScoreboard(challengeId, addToAlerts)}
          type="button"
          className={
            "btn btn" + (isFrozen ? "" : "-outline") + "-info my-2 mr-2"
          }
          disabled={isFrozen}
        >
          <i className="fas fa-snowflake"></i> {isFrozen ? "Frozen" : "Freeze"}{" "}
          scoreboard
        </button>
        {leaderboard.length && (
          <CSVLink {...csvReport}>
            <button type="button" className="btn btn-warning my-2 mr-2">
              <i className="fas fa-save"></i> Download scoreboard
            </button>
          </CSVLink>
        )}
      </div>
    </section>
  );
};

export default Commander;
