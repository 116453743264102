import React from 'react';
import { useNavigate } from 'react-router-dom';
import Authentication from '../../services/Authentication';
import "./navbar.scss";

/**
 * Will call logout service and redirect to login page
 * @param {Function} navigate React navigation function
 */
const _logout = (navigate) => {
  Authentication.logout()
    .then(_ => navigate('/'))
    .catch(err => console.error(err));
};

/**
 * Compute progression to return it as string
 * @param {string} challengeStart datetime of challenge start
 * @param {string} challengeEnd datetime of challenge end
 * @returns progression (eg.: "25%")
 */
const _progressCompute = (challengeStart, challengeEnd) => {
  if (!(challengeStart && challengeEnd)) return "0%";

  const t = Date.now();
  const dCS = new Date(challengeStart);
  const dcST = dCS.getTime();
  if (t < dcST) return null;

  const dCE = new Date(challengeEnd);
  const dcET = dCE.getTime();
  const percent = ~~(100 * (t - dcST) / (dcET - dcST));
  return `${percent > 100 ? 100 : percent}%`;
};

const Navbar = ({ username, isAdmin, children, dtChallengeStart, dtChallengeEnd }) => {
  const navigate = useNavigate();
  const progression = _progressCompute(dtChallengeStart, dtChallengeEnd);

  return (
    <section>
      <nav className="fixed-top text-white">
        <div id="nav-top" className="d-inline-flex justify-content-between w-100">
          <div id='navbar-logo' className='d-flex align-items-center justify-content-center'>
            <img src="media/logo.svg" alt="logo_datascientest" width={25} height={30} />
          </div>
          {
            !_progressCompute(dtChallengeStart, dtChallengeEnd) ?
            (
              <div className='text-center border-0 align-self-center'>
                <p className='mb-0'>Challenge not launched yet, it will start on {new Date(dtChallengeStart).toLocaleString()}</p>
              </div>
            )
            :
            (<div className="progress border-0 w-75 align-self-center">
              <div className="progress-bar progress-bar-striped bg-info" role="progressbar" style={{"width": progression}} aria-valuemin="0" aria-valuemax="100">{progression}</div>
            </div>)
          }
          <div className="dropdown">
            <button className="dropdown-toggle d-inline-flex align-items-center px-3 bg-transparent h-100 border-0 text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div className="picture-user">
                <img src="media/User-Icon.png" alt="photo_user" width={35} height={35} className="rounded-circle" />
              </div>
              <span className="text-capitalize mx-3" id="navbar-surname">{username}{isAdmin ? (<><br/> Admin</>) : ''}</span>
              <div className="ico_button">
                <i className="material-icons md-24 align-middle">keyboard_arrow_down</i>
              </div>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-item" onClick={() => _logout(navigate)}>Log out</span>
            </div>
          </div>
        </div>
        <div id="nav-left"></div>
      </nav>
      <div className="navbar-margin">
        {children}
      </div>
    </section>
  );
};

export default Navbar;
