import { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import "./MachineCommander.scss";

const _fetchMachines = (setMachines, addToAlerts) => {
  AdminService.getMachineList()
    .then((resp) => setMachines(resp))
    .catch((err) => {
      addToAlerts({
        status: "danger",
        message: err,
      });
      console.error(err);
    });
};

const _adminMachineHandler = (action, machineId) => {
  if (
    window.confirm(
      `Are you sure you want to ${action} ${
        machineId ? `this instance (${machineId})` : "all instances"
      }?`
    )
  ) {
    AdminService.handleMachine(action, machineId)
      .then(() => {
        alert(`Machine is ${action}ing…`);
      })
      .catch((error) => {
        alert("An error occurred");
      });
  }
};

const _statusButton = (status) => {
  let text = "Loading";
  let theme = "secondary";

  switch (status) {
    case "running":
      text = "Running";
      theme = "success";
      break;
    case "stopped":
      text = "Stopped";
      theme = "danger";
      break;
    default:
      text = "Loading";
      theme = "secondary";
  }
  return (
    <button
      type="button"
      className={`btn btn-sm btn-${theme} dropdown-toggle mx-auto`}
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {text}
    </button>
  );
};

const MachineCommander = ({ addToAlerts, showTitle = true }) => {
  const [machines, setMachines] = useState([]);

  useEffect(() => {
    _fetchMachines(setMachines, addToAlerts);
    const interval = setInterval(
      () => _fetchMachines(setMachines, addToAlerts),
      30000
    );
    return () => clearInterval(interval);
  }, [addToAlerts]);

  return (
    <section className="bg-white p-3 px-md-5">
      {showTitle && <h2 className="my-3">Hello Admin!</h2>}
      <div className="btn-group my-2 w-100 justify-content-center">
        <button
          type="button"
          onClick={() => _adminMachineHandler("start")}
          className="btn btn-success"
        >
          <i className="fas fa-plane-departure"></i> Start all
        </button>
        <button
          type="button"
          onClick={() => _adminMachineHandler("stop")}
          className="btn btn-danger"
        >
          <i className="fas fa-plane-arrival"></i> Stop all
        </button>
        <button
          type="button"
          onClick={() => _adminMachineHandler("reboot")}
          className="btn btn-info"
        >
          <i className="fas fa-sync-alt"></i> Reboot all
        </button>
      </div>
      <div className="my-3">
        {machines && (
          <>
            <div className="machine-list-item">
              <p className="mb-0">IP</p>
              <p className="mb-0">Owner</p>
              <p className="mb-0">Action</p>
            </div>
            <hr />
            {machines.map((machine, index) => (
              <div key={`machinelist-${index}`} className="machine-list-item">
                <p className="my-2">{machine.ip || "No IP"}</p>
                <p className="my-2" title={machine.owner}>
                  {machine.owner}
                </p>
                <div className="btn-group my-2">
                  {_statusButton(machine.status)}
                  <ul className="dropdown-menu">
                    {machine.status === "running" ? (
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            _adminMachineHandler("stop", machine.id)
                          }
                        >
                          <i className="fas fa-plane-arrival"></i> Stop machine
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            _adminMachineHandler("start", machine.id)
                          }
                        >
                          <i className="fas fa-plane-departure"></i> Start
                          machine
                        </button>
                      </li>
                    )}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          _adminMachineHandler("reboot", machine.id)
                        }
                      >
                        <i className="fas fa-sync-alt"></i> Reboot machine
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default MachineCommander;
