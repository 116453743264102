import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Subject from "../../components/subject/Subject";
import Uploader from "../../components/uploader/Uploader";
import Leaderboard from "../../components/leaderboard/Leaderboard";
import AdminService from "../../services/AdminService";
import UserService from "../../services/UserService";
import Spinner from "../../components/spinner/Spinner";
import "./dashboard.scss";
import Alerts from "../../components/alerts/Alerts";
import Commander from "../../components/commander/Commander";
import Opinion from "../../components/opinion/Opinion";
import MachineStatus from "../../components/machine-status/MachineStatus";
import MachineCommander from "../../components/machine-commander/MachineCommander";

/**
 * Check if challenge is started
 * @param {string} challengeStart datetime of challenge's start
 * @returns Boolean meaning if challenge is on
 */
const _checkStarted = (challengeStart) => {
  // If end not even specified
  if (!challengeStart) return true;

  const dateNow = Date.now();
  const dateChallengeStart = new Date(challengeStart);
  const dateChallengeStartTime = dateChallengeStart.getTime();
  return dateNow > dateChallengeStartTime;
};

/**
 * Check if challenge is still not ended
 * @param {string} challengeEnd datetime of challenge's end
 * @returns Boolean meaning if challenge is still on
 */
const _checkEnded = (challengeEnd) => {
  // If end not even specified
  if (!challengeEnd) return true;

  const dateNow = Date.now();
  const dateChallengeEnd = new Date(challengeEnd);
  const dateChallengeEndTime = dateChallengeEnd.getTime();
  return dateNow > dateChallengeEndTime;
};

const Dashboard = () => {
  const [data, setDatas] = useState({});
  const [alerts, setAlerts] = useState([]);
  const addToAlerts = useCallback(
    (alert = {}) => {
      const ts = Date.now();
      alert.ts = ts;
      setAlerts([...alerts, alert]);
    },
    [alerts]
  );

  // Fetch info
  useEffect(() => {
    let account, challenge, scoreboard;

    (async () => {
      try {
        account = await UserService.getUserInfo();
        challenge = await UserService.getChallengeInfo(account.challenge);
        scoreboard = await (account?.is_admin
          ? AdminService
          : UserService
        ).getScoreBoard(account.challenge);
        setDatas({ account, challenge, scoreboard });
      } catch (err) {
        console.error("err initialisation", err);
        addToAlerts();
      }
    })();
  }, [addToAlerts]);

  if (process.env.REACT_APP_ENV !== "production") {
    console.log(data);
  }

  return (
    <>
      <Navbar
        username={data?.account?.username}
        isAdmin={data?.account?.is_admin}
        dtChallengeStart={data?.challenge?.dt_start}
        dtChallengeEnd={data?.challenge?.dt_end}
      >
        {Object.keys(data).length ? (
          <section className="container-fluid p-3">
            <div className="row">
              <div className="col-lg-4 p-3">
                <Subject
                  title={data?.challenge?.name}
                  description={data?.challenge?.description}
                  attachments={[
                    {
                      link: data?.challenge?.subject_link,
                      name: "Subject",
                      is_available: _checkStarted(data?.challenge?.dt_start),
                    },
                    ...(data?.challenge?.attachments
                      ? data.challenge.attachments
                      : []),
                  ]}
                />
                <br />
                {data?.challenge?.is_heavy ? (
                  !data?.account?.is_admin ? (
                    <MachineStatus
                      usermail={data?.account?.email}
                      challengeId={data?.account?.challenge}
                    />
                  ) : (
                    <React.Fragment>
                      <Commander
                        challengeId={data?.account?.challenge}
                        addToAlerts={addToAlerts}
                        isFrozen={data?.challenge?.is_frozen}
                        leaderboard={data?.scoreboard?.scoreboard}
                      />
                      <MachineCommander
                        addToAlerts={addToAlerts}
                        showTitle={false}
                      />
                    </React.Fragment>
                  )
                ) : !data?.account?.is_admin ? (
                  <Uploader
                    challengeId={data?.account?.challenge}
                    addToAlerts={addToAlerts}
                    started={_checkStarted(data?.challenge?.dt_start)}
                    ended={_checkEnded(data?.challenge?.dt_end)}
                  />
                ) : (
                  <Commander
                    challengeId={data?.account?.challenge}
                    addToAlerts={addToAlerts}
                    isFrozen={data?.challenge?.is_frozen}
                    leaderboard={data?.scoreboard?.scoreboard}
                  />
                )}
                {!data?.account?.is_admin && data?.scoreboard?.is_frozen && (
                  <>
                    <br />
                    <Opinion />
                  </>
                )}
              </div>
              <div className="col-md p-3">
                <Leaderboard
                  data={data?.scoreboard?.scoreboard}
                  challengeId={data?.account?.challenge}
                  isAdmin={data?.account?.is_admin}
                  groupId={data?.account?.group}
                  userName={data?.account?.username}
                  frozen={
                    data?.scoreboard?.is_frozen
                      ? data?.scoreboard?.dt_frozen
                      : null
                  }
                  actions={true}
                />
              </div>
            </div>
          </section>
        ) : (
          <section className="py-5 text-center">
            <Spinner />
          </section>
        )}
        <Alerts alerts={alerts} />
      </Navbar>
    </>
  );
};

export default Dashboard;
